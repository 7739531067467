.listitemOpen {
  margin: auto;
  width: 1500px;
  height: 220px;
  margin-top: 20px;
  background-color: #ffffff;
  transition: all 0.1s ease-out;
  box-shadow: 0px 5px 5px #c0c0c0;
}
.listitemClosed {
  margin: auto;
  width: 1500px;
  height: 50px;
  margin-top: 20px;
  background-color: #eeeeee;
  transition: all 0.1s ease-out;
}
.listitemHidden {
  display: none;
}
.topBar {
  position: relative;
  width: 100%;
  height: 50px;
  margin: 0;
  padding: 0;
  background-color: #004f9e;
  box-shadow: 0px -2px 5px #dddddd;
  color: white;
}



.infoID {
  position: relative;
  top: 17px;
  float: left;
  width: 30%;
  height: 50px;
  margin: 0;
  margin-left: 15px;
  text-align: left;
  font-size: 0.8rem;
}
.infoName {
  width: 30%;
  height: 25px;
  position: relative;
  
  float: left;
  text-align: left;
}

.buttonID{
  position: relative;
  top: 12px;
  float: right;
  margin-right: 1rem
}

.headerID{
  float: left;
  position: relative;
  top: 17px;
  margin: 0;
}

.multidrop {
  width: 20%;
  height: 25px;
  position: relative;
  margin: 0;
  float: right;
  background-color: purple;
}

.toolbar {
  position: relative;
  width: 18%;
  height: 50px;
  float: left;
}

.infoDescription {
  width: 5%;
  height: 25px;
  position: relative;
  top: -8px;
  margin: 0;
  margin-left: 48%;
  text-align: center;
}

.label {
  float: left;
  height: 25px;
  position: relative;
  text-align: center;
  top: 15px;
}
.infoCheckbox {
  width: 20px;
  height: 25px;
  position: relative;
  text-align: center;
  top: 11px;
  margin-right: 1rem;
  float: left;
}

.infoNumber {
  width: 10%;
  height: 25px;
  position: relative;
  width: 100px;
  float: left;
}

.orderedlist {
  margin-left: 3%;
  width: 94%;
  padding-inline-start: 0;
  height: 170px;
  align-items: center;
}

.li {
  margin: auto;
  margin-top: 5px;
  display: inline-block;
  text-decoration: none;
  list-style-type: none;
  height: 150px;
}

.contentcontainer {
  position: absolute;
  margin-top: 10px;
  margin-left: 10px;
  top: 0;
}
