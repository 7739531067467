.content {
  margin-top: 210px;
}

.startscreen {
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(images/blennemann_background.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.version{
  position: absolute;
  bottom: 0;
  right: 0;
  color: white;
  margin-right: 10px;
}