.settings {
  width: 90%;
  height: 100%;
  margin-left: 20%;
}
.indented {
  width: 100%;
  height: 100%;
  margin-left: 10px;
}

.input {
  margin-left: 15px;
  text-align: center;
  width: 225px;
  height: 18px;
  background-color: white;
  border-radius: 20px;

  border-style: none;
}
.input:hover {
  background-color: #ffeedd;
}
.input:focus {
  background-color: #cceeff;
  border-style: none;
  outline: none;
}

.defaultsizebuttons {
  width: 20%;
  margin: auto;
  margin-left: 5px;
  height: 25px;
  background-color: white;
  border-style: none;
}

.divdefaultsizebuttons {
  width: 100%;
  padding: 0 0;
  display: flex;
  justify-content: left;
}

.defaultsizebuttons:hover {
  background-color: #ffb061;
}

.visibilityContainer {
  margin-top: 35px;
}

.radiobutton {
  margin-top: 5px;
}

.h3 {
  margin-top: 50px;
  margin-bottom: 15px;
}
.firsth3 {
  margin-top: 25px;
  margin-bottom: 15px;
}

.exportbutton {
  margin-left: 12.5%;
  width: 75%;
  height: 10vh;
  background-color: white;
  border-style: none;
  box-shadow: 3px 3px 10px #242424;
  border-radius: 42px;
  color: #004f9e;
}
.exportbutton:hover {
  background-color: #ffb061;
}

.exportbutton:active {
  background-color: #aaaaaa;
  color: white;
}

.defaultstatistics {
  width: 100%;
  margin: auto;
  margin-top: 20px;
  margin-left: 5px;
  height: 15px;
}

.statisticshidden{
  display: none;
}

.tableheader{
  width: 180px;
  text-align: left;
}

.tablecontent{
  width: 50px;
  text-align: left;
}

.tablerow{
  height: 30px;
}