.multiDropBar {
    height: 50px;
    z-index: 800;
    background-color: #004f9e;
  }

  .multiDropBarHovered {
    height: 50px;
    z-index: 800;
    background-color: #ffb061;
  }