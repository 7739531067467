.content {
  width: 222px;
  height: 125px;
  text-align: center;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  display: inline-block;
  z-index: 1000;
  margin: 6px;
  margin-top: 10px;
  background-color: #fafafa;
}

.contentInvisible {
  display: none;
}

.parent {
  position: relative;
  top: 0;
  left: 0;
}

.imagePreview {
  position: relative;
  top: 0;
  left: 0;
  box-shadow: 2px 2px 3px rgb(161, 161, 161);
  height: 125px;
}

.optionsField {
  width: 222px;
  height: 125px;
}
.optionsFieldHidden {
  display: none;
  width: 222px;
  height: 125px;
}

.options {
  position: absolute;
  top: 0;
  height: 63px;
}

.firstImage {
  position: absolute;
  top: 0px;
  left: 0px;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  height: 63px;
}

.secondImage {
  position: absolute;
  top: 0px;
  left: 0px;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  height: 63px;
}

.deleteOption {
  width: 222px;
  height: 62px;
}

.deleteImage {
  position: absolute;
  top: 64px;
  left: 0px;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
