.searchbar {
  width: 100%;
  height: 45px;
  position: fixed;
  top: 150px;
  background-color: white;
  z-index: 900;
  padding-top: 20px;
  margin-top:-20px;
}
.content {
  margin: auto;
  width: 40%;
  justify-content: center;
  display: flex;
}
.label {
  margin: auto;
  width: 20%;
}

.textfield {
  margin: auto;
  width: 80%;
  height: 25px;
  border-style: none;
  background-color: #ffffff;
  box-shadow: 0px 0px 5px #aaaaaa;
  border-radius: 25px;
  padding-left: 10px;
}

.textfield:hover {
  background-color: #ffeedd;
}
.textfield:focus {
  background-color: #cceeff;
  border-style: none;
  outline: none;
}
