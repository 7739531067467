.sidebarOpen {
  transform: translateX(0);
  right: 0;
  top: 0;
  height: 100%;
  width: 30%;
  position: fixed;
  z-index: 1001;
  background-color: #004f9e;
  transition: all 0.33s ease-out;
  color: white;
}

.sidebarClosed {
  transform: translateX(88%);
  right: 0;
  top: 0;
  height: 100%;
  width: 30%;
  position: fixed;
  background-color: #ffffff;
  z-index: 1001;
  transition: all 0.33s ease-out;
  box-shadow: -2px 0px 3px #eeeeee;
}

.button {
  margin-top: 5%;
  margin-left: 2%;
  height: 50px;
  width: 50px;
  background-color: #ffb061;
  border-style: none;
  transform: rotate(0deg);
  transition: all 0.53s ease-out;
}

.buttonClosed {
  margin-top: 5%;
  margin-left: 2%;
  height: 50px;
  width: 50px;
  background-color: #004f9e;
  transform: rotate(180deg);
  transition: all 0.53s ease-out;
  border-style: none;
}

.button:hover {
  background-color: #04325f;
  transform: rotate(180deg);
  transition: all 0.53s ease-out;
}

.buttonClosed:hover {
  background-color: #ffb061;
  transform: rotate(0deg);
  transition: all 0.53s ease-out;
}

.titlebar {
  float: left;
  display: inline;
  width: 80%;
  margin: auto;
}

.buttonimage {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  width: 50px;
}
