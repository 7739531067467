.CSVReaderHighlighted {
  width: 40%;
  margin: auto;
  margin-top: 15vh;
  background-color: #0082b6;
  padding: 30px;
  padding-bottom: 60px;
  border-radius: 10px;
  border: 2px solid #0082b6;
  box-shadow: 5px 5px 10px #001120;
}

.CSVReader {
  width: 40%;
  margin: auto;
  margin-top: 15vh;
  background-color: #004f9e;
  padding: 30px;
  padding-bottom: 60px;
  border-radius: 25px;
  border: 2px solid #004f94;
  box-shadow: 5px 5px 10px #001120;
}

.centered {
  display: flex;
  justify-content: center;
  width: 50%;
  margin: auto;
  margin-top: 5%;
}
.centeredtwo {
    display: flex;
    justify-content: center;
    width: 60%;
    margin: auto;
    margin-top: 20px;
    color: white;
  }

  .shakeImage:hover{
    animation: shake 0.5s;
    animation-iteration-count: infinite;
  }

  @keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }

  
