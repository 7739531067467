.header{
    background-color: white;  
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 130px;
    z-index: 1000;
    box-shadow: 0px 5px 5px #eeeeee;
}

.title{
    margin: 0;
    margin-left: 5%;
    margin-top: 3vh;
    font-size: 60px;
    padding-left: 4%;
    color: #555555;
}