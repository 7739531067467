.csvoptions{
    background-color: white;
    width: 40%;
    margin: auto;
    margin-top: 2vh;
    padding: 30px;
    padding-top: 10px;
    padding-bottom: 20px;
    border-radius: 25px;
    border: 2px solid white;
    box-shadow: 5px 5px 10px #001120;
}

.radio{
    margin: auto;
    width: 20%;
}

.table{
    width: 100px;
    text-align: center;
}
.h4{
    margin-left: 30px;
}

.optionstext{
    text-align: center;   
}